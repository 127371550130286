import React from 'react'
import Footer from '../../layout/Footer'
import Header from '../../layout/Header'


import Error_500 from '../../components/errors/errors_500'

const Error500 = () => {
  return (
    <>
    
     <Error_500 />

    </>

  )
}

export default Error500