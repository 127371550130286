import React from 'react'
import Footer from '../../layout/Footer'
import Header from '../../layout/Header'


import Error_404 from '../../components/errors/errors_404'

const Error404 = () => {
  return (
    <>

     <Error_404 />

    </>

  )
}

export default Error404